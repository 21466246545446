import React from 'react';
import { Link } from "react-router-dom";
import './Contest.css';

interface ContestProps {
  button: boolean;
}

function Programme(props: ContestProps) {
  return (
    <div className="programme">
      <div className="container">
        <h1>Co všechno tě čeká na veletrhu?</h1>
        <Link to="/plan" className="navigation-button-homepage">Vystavující firmy</Link>
        <div className="cards">
          <div className="card-large ">
            <img src="/data/images/firmy1.png" />
            <h3>65+ technických firem</h3>
            <p>Na veletrhu potkáš firmy z různých oborů:</p>
            <ul>
              <li>Strojní inženýrství</li>
              <li>Elektrotechnika</li>
              <li>Informatika</li>
              <li>Ekonomie</li>
              <li>Chemie</li>
              <li>Stavební inženýrství</li>
              ... a další
            </ul>
            {props.button && (
              <Link to="/plan">
                <button>Prohlédni si firmy</button>
              </Link>
            )}
          </div>
          <a href="/contest" className='interactive-card'>
            <div className="card">
              <img src="/data/images/appleWatch.webp" alt="Apple watch" />
              <h4>Soutěž</h4>
              <p>Vyhraj věcné ceny! <br /> Klikni pro víc informací. </p>
              {/* {props.button && (
              <Link to="/contest">
                <button>Zapojít se do soutěže</button>
              </Link>
            )} */}
            </div>
          </a>
          <a href="/timelinePage" className='interactive-card'>
            <div className="card">
              <img src="/data/images/class_lecture.jpeg" />
              <h4>Workshopy</h4>
              {/* <p>Dojdi na přednášku 10-11 na téma osobní a profesní rozvoj + bude workshop 12-13 od firmy quanti na téma Unity s virtuální realitou</p> */}
              <p>Přijď si poslechnout interaktivní přednášku <br /> nebo workshop.</p>
              {/* {props.button && (
              <Link to="/contest">
                <button>Rezervovat si misto</button>
              </Link>
            )} */}
            </div>
          </a>
          <a href="/timelinePage" className='interactive-card'>
            <div className="card">
              <img src="/data/images/price4.jpg" alt="Kamera Lamax řady x9.1" />
              <h4>Konzultace</h4>
              <p>Zkonzultuj své CV nebo profil na LinkedIn s vybranými zástupci firem.</p>
            </div>
          </a>
          <a href="/timelinePage" className='interactive-card'>
            <div className="card">
              <img src="/data/images/price5.jpg" alt="Kamera Lamax řady x9.1" />
              <h4>Doprovodný program</h4>
              <p>Máme připravený bohatý <br /> doprovodný program! 🍺 </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Programme;
