import React, { Component } from 'react';
import Header from './../components/Header';
import Timeline from '../components/Timeline';
import Partners from '../components/Partners';

class TimelinePage extends Component {
  render() {
    return (
      <div className="">

        <Header place="VUT fakulta Podnikatelská" date="12. Dubna" />
        <div className='main-content'>          
          <Timeline />
        </div>

        <Partners />
      </div>
    );
  }
}

export default TimelinePage;