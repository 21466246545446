import React, { Component, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import HTMLFlipBook from 'react-pageflip';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Slider from "react-slick";
import './Partners.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function Partners() {
  var generalSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  var plusSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // Width up to 768px
        settings: {
          slidesToShow: 3, // Show fewer slides on smaller screens
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480, // Width up to 480px
        settings: {
          slidesToShow: 3, // Show even fewer slides on very small screens
          slidesToScroll: 1,
        }
      }
    ]
  };

  var standardSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // Width up to 768px
        settings: {
          slidesToShow: 3, // Show fewer slides on smaller screens
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480, // Width up to 480px
        settings: {
          slidesToShow: 3, // Show even fewer slides on very small screens
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div>
      <div className="">
        <Container className="partner-container" fluid>       

          <div className="small-box"> <div className="partners-text"> Partneři Plus </div>
            <Slider {...plusSettings}>
              <div>
                <img className="partner-logo" src="data/icons/plus/abb_white.png" />
              </div>
              <div>
                <img className="partner-logo" src="data/icons/plus/valeo.jpg" />
              </div>
              <div>
                <img className="partner-logo" src="data/icons/plus/akkodis_white.png" />
              </div>
              <div>
                <img className="partner-logo" src="data/icons/plus/konplan_white.png" />
              </div>
              <div>
                <img className="partner-logo" src="data/icons/plus/vinci_white.png" />
              </div>
            </Slider>
          </div>

          <div className="middle-box">
            <div className="partners-text"> Generální partner </div>
            <div className="logo-center">
              <img className="partner-logo" src="data/icons/general/skupina_cez_white.png" />
            </div>
          </div>

          <div className="small-box"> <div className="partners-text"> Partneři Standard </div>
            <Slider {...standardSettings}>
              <div>
                <img className="partner-logo" src="data/icons/plus/digiteq_white.png" />
              </div>
              <div>
                <img className="partner-logo" src="data/icons/standard/ceps_white.png" />
              </div>
              <div>
                <img className="partner-logo" src="data/icons/standard/ujv_white.png" />
              </div>
              <div>
                <img className="partner-logo" src="data/icons/standard/allegro_white.png" />
              </div>
              <div>
                <img className="partner-logo" src="data/icons/standard/plzen_white.png" />
              </div>
              <div>
                <img className="partner-logo" src="data/icons/standard/procter_white.png" />
              </div>
            </Slider>
          </div>
        </Container>
      </div>
    </div>

  );
}

export default Partners;
