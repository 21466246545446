import React, { Component } from 'react';
import Header from './../components/Header';
import Contest from '../components/Contest';
import Partners from '../components/Partners';

class ContestPage extends Component {
  render() {
    return (
      <div className="contest-page">

        <Header place="VUT fakulta Podnikatelská" date="12. Dubna" />

        <div className='main-content'>
          <div className='pt-40'>
            <div className='container'>
              <h1>Veletržní soutěž</h1>
              <p>Víc informací už brzy!</p>

              {/* <p>
                  Veletržní soutěž je každoroční událost, která spojuje nejlepší firmy a jednotlivce z celého světa. 
                  Tento rok se účastní více než 100 různých organizací, které předvádí inovativní produkty a služby. 
                  Cílem soutěže je nejen ukázat sílu technologických novinek, ale také podpořit růst a rozvoj startupů.
                </p>

                <h5>Pravidla soutěže</h5>
                <ul>
                  <li>Účastníci musí být registrováni do stanoveného termínu.</li>
                  <li>Každý účastník má právo prezentovat pouze jeden produkt.</li>
                  <li>Soutěžící musí dodržovat všechny pokyny organizátorů během akce.</li>
                  <li>Všechny soutěžní prezentace budou hodnoceny odbornou komisí.</li>
                </ul> */}

              <h5 className='mt-5'>Ceny</h5>
              <p>
                Ceny pro vítěze zahrnují:
              </p>
              <div className='mx-auto inline-block'>
                <ul>
                  <li>První místo: <strong>Vymakané apple watch</strong></li>
                  <li>Druhé místo: <strong>Bluetooth reproduktor</strong></li>
                  <li>Třetí místo: <strong>Softshell bunda</strong></li>
                </ul>
              </div>

            </div>
          </div>
          {/* <Contest button={false} /> */}
        </div>
        <div className='fixed bottom-0 w-full'>
          <Partners />
        </div>
      </div>
    );
  }
}

export default ContestPage;
