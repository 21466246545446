import React, { useState, useEffect } from 'react';
import { useTable, Column, useFilters } from 'react-table';
import { matchSorter } from 'match-sorter';
import { CompanyJobfairInfo, stallMap } from '../stallMapData_2025';
import { useLocation } from 'react-router-dom';
import './InteractivePlan.css';
import ButtonForm from './ButtonForm';
import PdfViewer from './PdfViewer';
import ScrollToTop from './ScrollToTop';
import JobfairPlanPage from '../pages/JobfairPlan';

interface PlanProps {
  svgPath: string;
  svgPathMobile: string;
}

var activeStall = 0;
const STALL_STYLE_HIGHLIGHTED = "fill: #027cdb;fill-opacity:0.5;stroke:none;stroke-width:0.782855;stroke-opacity:0.966825";
const STALL_STYLE_IDLE = "fill:white;fill-opacity:0;stroke:none;stroke-width:0;stroke-opacity:0";
const STALL_STYLE_ACTIVE = "fill: #ed1f24;fill-opacity:0.9;stroke:none;stroke-width:0.782855;stroke-opacity:0.966825";


type TableItem = {
  name: string;
  stall: string;
  //  major: string;
}

const onMobile = window.innerWidth < 767;

// function getCompanies(toggledButtonsMajorA: Set<string>, toggledButtonsMajorB: Set<string>): Set<number> {
//   var list = new Set<number>;
//   for (var obor of toggledButtonsMajorA){
//     for (var company of stallMap.values()) {
//       console.log(toggledButtonsMajorA)

//       if(obor === "Strojní" && company.s_strojni){
//         list.add(company.stallNumber)
//       }
//       if(obor === "Elektro" && company.s_elektro){
//         list.add(company.stallNumber)
//       }
//       if(obor === "Informatika" && company.s_it){
//         list.add(company.stallNumber)
//       }
//       if(obor === "Ekonomie" && company.s_ekonomie){
//         list.add(company.stallNumber)
//       }
//       if(obor === "Chemie" && company.s_chemie){
//         list.add(company.stallNumber)
//       }
//       if(obor === "Stavebnictví" && company.s_stavebnictvi){
//         list.add(company.stallNumber)
//       }
//       if(obor === "Ostatní" && company.s_ostatni){
//         list.add(company.stallNumber)
//       }
//     }
//   }

//   console.log(list)
//   return list;
// }

function getCompanies(toggledButtonsMajorA: Set<string>, toggledButtonsMajorB: Set<string>): Set<CompanyJobfairInfo> {
  var list = new Set<CompanyJobfairInfo>();

  // Iterate through each company
  for (var company of stallMap.values()) {
    let matchesObor = false;
    let matchesUvazek = false;

    // Check if the company matches any selected "obor"
    if (toggledButtonsMajorA.has("Strojní") && company.s_strojni) matchesObor = true;
    if (toggledButtonsMajorA.has("Elektro") && company.s_elektro) matchesObor = true;
    if (toggledButtonsMajorA.has("Informatika") && company.s_it) matchesObor = true;
    if (toggledButtonsMajorA.has("Ekonomie") && company.s_ekonomie) matchesObor = true;
    if (toggledButtonsMajorA.has("Chemie") && company.s_chemie) matchesObor = true;
    if (toggledButtonsMajorA.has("Stavebnictví") && company.s_stavebnictvi) matchesObor = true;
    if (toggledButtonsMajorA.has("Ostatní") && company.s_ostatni) matchesObor = true;

    // Check if the company matches any selected "uvazek"
    if (toggledButtonsMajorB.has("Plný úvazek")) matchesUvazek = true;
    if (toggledButtonsMajorB.has("Částečný úvazek") && company.m_cast_uvazek) matchesUvazek = true;
    if (toggledButtonsMajorB.has("Diplomka") && company.m_diplomka) matchesUvazek = true;
    if (toggledButtonsMajorB.has("Stáž") && (company.m_internship || company.m_trainee)) matchesUvazek = true;
    if (toggledButtonsMajorB.has("Brigáda") && company.m_brigada) matchesUvazek = true;

    // If the company matches both "obor" and "uvazek", add it to the list
    if (matchesObor && matchesUvazek) {
      list.add(company);
    }
  }

  return list;
}

function handlePlanToggle(): any {
  const svgContainer = document.getElementById('mobile-jobfair-plan');
  if (svgContainer !== null) {
    svgContainer.style.display = svgContainer.style.display === 'none' ? 'block' : 'none';
  }
};

const InteractivePlan: React.FC<PlanProps> = (props) => {
  const [toggledButtonsMajorA, setToggledButtonsMajorA] = useState(new Set<string>());
  const [toggledButtonsMajorB, setToggledButtonsMajorB] = useState(new Set(["Plný úvazek", "Částečný úvazek", "Diplomka", "Stáž", "Brigáda"]));
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchWasRecent, setSearchWasRecent] = useState<boolean>(true);

  const STALL_NUM = 68;

  const [active, setActive] = useState(0);

  var filteredCompanies: Set<CompanyJobfairInfo>;

  var planId: string;

  if (onMobile) {
    planId = "jobfair-plan-mobile";
  }
  else {
    planId = "jobfair-plan";
  }

  // Hook to access location object
  const location = useLocation();

  useEffect(() => {
    var jobfairPlan: any = document.getElementById(planId);

    // Highlight stalls based on toggledButtonsMajorA
    const highlightStallsBlue = () => {
      if (jobfairPlan) {
        var svgDoc = jobfairPlan.contentDocument;
        filteredCompanies = getCompanies(toggledButtonsMajorA, toggledButtonsMajorB);
        filteredCompanies.forEach((company) => {
          const stall = svgDoc.getElementById("rect-stall-" + company.stallNumber);
          if (stall) {
            stall.setAttribute("style", STALL_STYLE_HIGHLIGHTED);
          }
        });
      }
    };

    const highlightStalls = () => {
      if (jobfairPlan) {
        var svgDoc = jobfairPlan.contentDocument;
        // Reset all stalls to idle style first, if necessary
        for (let i = 1; i <= STALL_NUM; i++) {
          const stall = svgDoc.getElementById("rect-stall-" + i);
          stall?.setAttribute("style", STALL_STYLE_IDLE);

          stall?.addEventListener("click", function (event: any) {
            // Reset all stalls to idle
            for (let i = 1; i <= STALL_NUM; i++) {
              const stall = svgDoc.getElementById("rect-stall-" + i);
              stall?.setAttribute("style", STALL_STYLE_IDLE);
            }
            highlightStallsBlue();
            setActive(i);
            activeStall = i;
            // Highlight the clicked stall
            event.currentTarget.setAttribute("style", STALL_STYLE_ACTIVE);
            showActiveCompany(activeStall);
          }, false);

          // Change cursor on hover
          stall?.addEventListener("mouseenter", function (event: any) {
            event.currentTarget.style.cursor = 'pointer';
          }, false);
        }

        // firstly set blue highlight
        highlightStallsBlue();

        // secondly set active stall, red highlight
        if (activeStall && !onMobile) {
          const activeStall_div = svgDoc.getElementById("rect-stall-" + activeStall);
          activeStall_div.setAttribute("style", STALL_STYLE_ACTIVE);
        }
      }
    };

    const highlightStallMobile = (stallNumber_: number | null) => {
      console.log("highlightStallMobile: " + stallNumber_);
      if (jobfairPlan && stallNumber_ != null) {

        var svgDoc = jobfairPlan.contentDocument;
        for (let i = 1; i <= STALL_NUM; i++) {
          const stall = svgDoc.getElementById("rect-stall-" + i);
          stall?.setAttribute("style", STALL_STYLE_IDLE);
        }
        highlightStallsBlue();
        const stall = svgDoc.getElementById("rect-stall-" + stallNumber_);
        stall?.setAttribute("style", STALL_STYLE_ACTIVE);
        console.log("Atribute set: ");
      }
    };

    const companiesContainer = document.getElementById('companiesContainer');

    const showActiveCompany = (stallNumber_: number | null) => {

      while (companiesContainer!.firstChild) {
        companiesContainer!.removeChild(companiesContainer!.firstChild);
      }
      //console.log("highlightStallMobile: " + stallNumber_);
      stallMap.forEach((company) => {
        if (company.stallNumber == stallNumber_) {
          showCompany(company, 'block');
        }
      });

    }

    const showCompanyList = () => {

      const element = document.getElementById('hint');
      if (element && toggledButtonsMajorA.size != 0) {
        element.style.display = 'none';
      }

      if (searchWasRecent && toggledButtonsMajorA.size != 0) {
        setSearchTerm('');
        setSearchWasRecent(false);
      }

      // Clear existing content
      while (companiesContainer!.firstChild) {
        companiesContainer!.removeChild(companiesContainer!.firstChild);
      }

      filteredCompanies = getCompanies(toggledButtonsMajorA, toggledButtonsMajorB);

      filteredCompanies.forEach((company) => {
        showCompany(company, 'none');
      });

      if (toggledButtonsMajorA.size == 0 && toggledButtonsMajorB.size == 5) {
        showAllCompanies();
      }

      if (toggledButtonsMajorA.size == 0 && toggledButtonsMajorB.size != 5) {
        const empty = document.getElementById('empty');
        if (empty) {
          empty.style.display = 'block';
        }
      }

    };

    const showCompanyListBySearch = () => {
      while (companiesContainer!.firstChild) {
        companiesContainer!.removeChild(companiesContainer!.firstChild);
      }

      const searchFilter = (company: CompanyJobfairInfo) => {
        return company.companyName.toLowerCase().includes(searchTerm.toLowerCase());
      };

      var filtered = Array.from(stallMap.values()).filter(searchFilter);

      if (toggledButtonsMajorA.size == 0) {
        setSearchWasRecent(true);
      }

      filtered.forEach((company) => {
        showCompany(company, 'none');
      });

    };

    const showAllCompanies = () => {
      for (var company of stallMap.values()) {
        showCompany(company, 'none');
      }
    };

    const showCompany = (company: CompanyJobfairInfo, display: string) => {
      // Create the company row (visible part) including the stall number
      const companyRow = document.createElement('div');
      //companyRow.textContent = `${company.stallNumber == 66 ? "Nope" : "Stánek"} ${company.stallNumber} | ${company.companyName}`;
      companyRow.textContent = `${company.stallNumber == 68 ? "" : `Stánek ${company.stallNumber} |`} ${company.companyName}`;
      companyRow.classList.add('company-row'); // Use for styling
      companyRow.style.cursor = 'pointer';

      // Info container (hidden part) for additional details
      const infoContainer = document.createElement('div');
      infoContainer.classList.add('info-container'); // Use for styling

      // Company description
      const description = document.createElement('p');
      description.textContent = company.description;
      infoContainer.appendChild(description);

      // Company link (if available)
      if (company.www) {
        const link = document.createElement('a');
        link.href = company.www;
        link.textContent = 'Zjistit více';
        link.target = '_blank'; // Open in a new tab
        infoContainer.appendChild(link);
        link.style.backgroundColor = '#1b75bb'; // Background color
      }

      const stall = document.createElement('a');
      stall.id = 'reference-to-stall';
      stall.textContent = 'Ukaž na plánku';
      stall.style.backgroundColor = '#1b75bb'; // Background color
      stall.addEventListener('click', (event) => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top smoothly
      });

      infoContainer.appendChild(stall);

      // Initially hide the info container
      infoContainer.style.display = display;

      // Toggle the visibility of the info container on click
      companyRow.addEventListener('click', () => {
        highlightStallMobile(company.stallNumber);
        infoContainer.style.display = infoContainer.style.display === 'none' ? 'block' : 'none';
      });

      // Append elements to the companies container
      companiesContainer!.appendChild(companyRow);
      companiesContainer!.appendChild(infoContainer);
    }

    // Function to be called after the SVG is loaded
    const onSvgLoad = () => {
      highlightStalls();

      const params = new URLSearchParams(location.search);
      const numberParam = params.get('number');

      if (numberParam) {
        const stall_num = parseInt(numberParam, 10);
        console.log("Number from URL:", stall_num);

        showActiveCompany(stall_num);
        highlightStallMobile(stall_num);
        setActive(stall_num);

        window.history.replaceState(null, "", "/plan");
      }

      const isMobile = window.innerWidth <= 768; // Adjust based on your breakpoint
      if (isMobile) {
        window.scrollTo({ top: 200, behavior: "smooth" });
      }
    };

    // Add event listener on load to highlight stalls
    //jobfairPlan?.addEventListener("load", highlightStalls, false);
    companiesContainer?.addEventListener("load", showCompanyList, false);
    jobfairPlan.addEventListener("load", onSvgLoad, false);

    if (jobfairPlan && jobfairPlan.contentDocument) {
      highlightStalls();
      showCompanyList();
      if (searchTerm != '') {
        showCompanyListBySearch();
      }
    }

    return () => {
      jobfairPlan?.removeEventListener("load", highlightStalls, false);
      companiesContainer?.removeEventListener("load", showCompanyList, false);
      jobfairPlan.removeEventListener("load", onSvgLoad, false);
    };
  }, [toggledButtonsMajorA, toggledButtonsMajorB, searchTerm]); // Depend on toggledButtonsMajorA to re-run when it changes


  return (


    <div className="container-column pt-24">
      {/* Mobile device layout ------------------------ */}
      <div className="mobile-version">
        <div className='mobile-planek1'>
          <object className='w-full' data={props.svgPathMobile} type="image/svg+xml" id="jobfair-plan-mobile" >
            Plánek Veletrhu pracovních příležitostí iKariéra 2025
          </object></div>
        <h4 id='hint' className='right-hint'>Zvol si obor 👇</h4>
        <div className='mobile-buttonform'>
          <ButtonForm
            toggledButtonsMajorA={toggledButtonsMajorA}
            setToggledButtonsMajorA={setToggledButtonsMajorA}
            toggledButtonsMajorB={toggledButtonsMajorB}
            setToggledButtonsMajorB={setToggledButtonsMajorB}
          />
        </div>
        <h4 id='hint' className='centered-hint'>⇓ Seznam firem ⇓</h4>
        {/* <button id="toggleSvgButton"  onClick={() => handlePlanToggle()} >Ukázat na plánku</button> */}
        <div className='search-mobile'>

          <input className='input-mobile'
            type="text"
            placeholder="Vyledej podle názvu"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)
            }
          />
        </div>
        <div id="companiesContainer">
        </div>
        <h5 id='empty' className='centered-hint mb-2' style={{ display: 'none', color: 'red' }}>Vyber obor!</h5>
      </div>
      {/* Web browser layout ------------------------ */}
      <div className="container-row">
        <div className="buttonform1">
          <ButtonForm
            toggledButtonsMajorA={toggledButtonsMajorA}
            setToggledButtonsMajorA={setToggledButtonsMajorA}
            toggledButtonsMajorB={toggledButtonsMajorB}
            setToggledButtonsMajorB={setToggledButtonsMajorB}
          />
        </div>
        <div className="h-screen absolute pt-0 flex justify-center">
          <object className='svg_img ml-20 mr-24 mt-8 z-0' data={props.svgPath} type="image/svg+xml" id="jobfair-plan">
            Plánek Veletrhu pracovních příležitostí iKariéra 2025
          </object>
        </div>
        <div className="w-full h-screen flex justify-between">
          <div className='w-1/5 h-1/2 z-10 choose-options'>
            <div className="w-1/2 h-full container-column card-background z-50">
              <div className='buttonform'>
                <ButtonForm
                  toggledButtonsMajorA={toggledButtonsMajorA}
                  setToggledButtonsMajorA={setToggledButtonsMajorA}
                  toggledButtonsMajorB={toggledButtonsMajorB}
                  setToggledButtonsMajorB={setToggledButtonsMajorB}
                />
              </div>
            </div>
          </div>
          <div className='w-1/2 h-1/2 z-10 card-company'>
            <div className=" w-1/2 h-full container-column card-background z-50">
              <div className="card-subtitle">
                <h3>{active ? (active === 68 ? "Nepřímá účast" : "Stánek č. " + active) : "Vyber firmu kliknutím na stánek v plánku!"}</h3>
              </div>
              <div className="card-title"><a href={stallMap.get(active)?.www} target='_blank' rel="noreferrer"><h1>{stallMap.get(active)?.companyName}</h1></a></div>
              <div className="card-description">{stallMap.get(active)?.description}</div>
            </div>
          </div>

        </div>
        <div id="companiesContainer">
        </div>
      </div>
    </div>
  );
}
<div id="companyList"></div>

export default InteractivePlan;
