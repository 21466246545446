import React, { Component } from 'react';
import { ReactDOM } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
//import * as FaIcons from 'react-icons/fa';
import './Timeline.css';
import { Link } from 'react-router-dom';
import { CompanyJobfairInfo, stallMap } from '../stallMapData_2025';

class Timeline extends Component {

  photos = [
    { src: '/static/lineup/cv.JPG' }
  ];

  render() {
    // Function to create the companyNumbers object dynamically
    const createCompanyNumbers = (stallMap: Map<number, CompanyJobfairInfo>): { [key: string]: number } => {
      const companyNumbers: { [key: string]: number } = {};
      stallMap.forEach((companyInfo) => {
        // Check if stallNumber is not null before assigning it
        if (companyInfo.stallNumber !== null) {
          companyNumbers[companyInfo.companyName] = companyInfo.stallNumber;
        } else {
          // Optionally, assign a default value or skip
          companyNumbers[companyInfo.companyName] = 0; // For example, -1 for missing stall number
        }
      });
      return companyNumbers;
    };
    // Call the function to generate the object
    const companyNumbers = createCompanyNumbers(stallMap);

    /*     const companyNumbers: { [key: string]: number } = {
          "Digiteq": 101,
          "MANN+HUMMEL": 102,
          "Apify": 103,
          "Ernst & Young": 104,
          "KPMG": 105,
          "KOMFORT": 106,
          "ABB": 107,
          "ÚJV ŘEŽ": 108,
          "Deloitte": 109,
          "Mobis": 110
        };
     */
    console.log(companyNumbers);


    // Define the table rows (without separate row variables, inside JSX)
    const rows = [
      {
        time: "10:00-11:00",
        companies: ["Digiteq", "MANN+HUMMEL", "Apify"]
      },
      {
        time: "11:00-12:00",
        companies: ["Ernst & Young", "KPMG", "KOMFORT"]
      },
      {
        time: "12:00-13:00",
        companies: ["ABB", "ÚJV Řež", "Deloitte Advisory"]
      },
      {
        time: "13:00-14:00",
        companies: ["MOBIS", "Apify", "ABB"]
      },
      {
        time: "14:00-15:00",
        companies: ["KOMFORT", "Deloitte Advisory", "KPMG"]
      }
    ];

    return (
      <div className='main-content'>
        <div className='pt-40 text-center'>
          <h1 className="">Program veletrhu</h1>
          <div>
            <section className="container pb-0">

              <p className="text-justify">
                Veletrh iKariéra <strong>začíná v 9:00</strong> hodin a končí odpoledne. Vyhodnocení soutěže proběhne ve <strong>14:00</strong> hodin.
                Kromě široké nabídky firem jsme pro tebe připravili i bohatý doprovodný program. Můžeš se těšit na:
              </p>
              <ul className="mt-4 space-y-2 list-none sm:ml-8">
                <li className="flex items-center gap-2 pl-4">
                  <span>⚽</span> Bodyzorbing – zábava v nafukovacích koulích
                </li>
                <li className="flex items-center gap-2 pl-4">
                  <span>⚖️</span> Slackliny – otestuj svou rovnováhu
                </li>
                <li className="flex items-center gap-2 pl-4">
                  <span>☕</span> Výborná káva od společnosti Kofi-Kofi
                </li>
                <li className="flex items-center gap-2 pl-4">
                  <span>🏎️</span> Simulátor formule od TU Brno Racing
                </li>
                <li className="flex items-center gap-2 pl-4">
                  <span>🔧</span> Technologický stánek od Strojlabu
                </li>
                <li className="flex items-center gap-2 pl-4">
                  <span>🕶️</span> Virtuální realita – ponoř se do digitálního světa
                </li>
                <li className="flex items-center gap-2 pl-4">
                  <span>🏥</span> Zdravotnická osvěta od organizace IFMSA
                </li>
                <li className="flex items-center gap-2 pl-4">
                  <span>🏒</span> Prezentace hokejového klubu VUT Cavaliers Brno
                </li>
                <li className="flex items-center gap-2 pl-4">
                  <span>⚡</span> Energetická vzpruha od Red Bullu
                </li>
              </ul>
            </section>

            <section className='container'>
              <ul>
                <li>
                  <h3 className='text-justify text-[#1b75bb]'>Kariérní poradenství</h3>

                  <div className="text-justify cv-container my-1">
                    <div className="row">
                      <p>
                        Pomůžeme ti vylepšit tvoje profesní dokumenty, jako je CV a profil na LinkedInu, aby byly atraktivní pro potenciální zaměstnavatele.
                        Na místě budou odborníci, kteří ti rádi poradí a doporučí, jak zaujmout.
                      </p>
                      <div className="photo-container col-12 col-md-3">
                        {this.photos.map((photo, index) => (
                          <div className="col-md-12 col-12 mb-3" key={index}>
                            <img src={photo.src} alt={`Timeline Photo ${index}`} className="img-fluid" />
                          </div>
                        ))}
                      </div>

                      <div className="table-container col-12 col-md-9 row">
                        <div className="col-12 text-left reasons">
                          <ul>
                            <li>
                              <strong>Osobní poradenství:</strong>
                              <p>
                                Konzultace na veletrhu ti umožní získat individuální zpětnou vazbu na tvé CV a LinkedIn profil od odborníků, kteří ti mohou doporučit konkrétní zlepšení.                              </p>
                            </li>
                            <li>
                              <strong>Profesionální profilová fotografie:</strong>
                              <p>
                                Přímo na veletrhu budeš mít jedinečnou možnost nechat si zdarma pořídit profesionální profilovou fotografii.
                                Zkušený fotograf s bílým plátnem se postará o kvalitní snímek, který skvěle doplní tvůj LinkedIn profil či životopis.
                                Profesionální fotka ti pomůže udělat lepší první dojem na potenciální zaměstnavatele.
                              </p>
                            </li>
                            <li><strong>Networking:</strong>
                              <p>
                                Můžeš navázat cenné kontakty s profesionály, kteří ti mohou pomoci s budoucí kariérou nebo tě seznámit s novými pracovními příležitostmi.
                              </p>
                            </li>
                            <li><strong>Vylepšení profesního profilu:</strong>
                              <p>
                                Odborníci ti pomohou optimalizovat tvůj LinkedIn profil tak, aby byl atraktivní pro potenciální zaměstnavatele a headhuntery.
                              </p>
                            </li>
                            <li>
                              <strong>Praktické tipy:</strong>
                              <p>
                                Získáš konkrétní tipy, jak efektivně prezentovat své dovednosti a zkušenosti, což zvýší tvoje šance na úspěch při hledání práce.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <p className='my-4'>
                        Konzultace budou poskytovat přímo zaměstnanci náborových oddělení a HR manažeři zúčastněných firem.
                        Nepropásni tuto jedinečnou příležitost a připrav se na konzultaci v uvedených časech! Kliknutím na firmu o kterou máš zájem se dostaneš na plán veletrhu.
                      </p>

                      <div className="d-flex justify-content-center align-items-center md:w-10 p-0">
                        <table className="col-11 md:ml-0 !ml-0">
                          <thead>
                            <tr>
                              <th className="w-25 text-center">Čas</th>
                              <th className="w-25 text-center">Konzultace CV</th>
                              <th className="w-25 text-center">Konzultace CV</th>
                              <th className="w-25 text-center">Konzultace LinkedIn</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rows.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                <td className="w-25">{row.time}</td>
                                {row.companies.map((company, index) => {
                                  // Find the company number using the partial match
                                  const companyNumber = Object.keys(companyNumbers).find(companyName =>
                                    companyName.toLowerCase().includes(company.toLowerCase())
                                  );

                                  // If we find a company number, get it from companyNumbers
                                  const companyNumberValue = companyNumber ? companyNumbers[companyNumber] : null;

                                  return (
                                    <td key={index} className="w-25 cursor-pointer">
                                      <a
                                        href={`/plan?number=${companyNumberValue}`}
                                        className="block no-underline text-white"
                                      >
                                        {company}
                                      </a>
                                    </td>
                                  );
                                })}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>



                    </div>
                  </div>
                </li>

                <li>
                  <h3 className="text-justify text-[#1b75bb] mt-5 mb-3">Workshopy</h3>
                  <p className="my-4 text-justify">
                    V jednotlivých časových blocích budou firmy prezentovat své projekty a aktuální témata, která ti pomohou lépe pochopit jejich technologické směřování a firemní kulturu.
                    Získáš cenné informace o inovacích, na kterých pracují, a poznáš odborníky z praxe, kteří ti přiblíží, jak vypadá práce v jejich týmech.
                    Workshopy se budou konat v přednáškové učebně E339.
                  </p>
                  <h4 className="text-center text-[#1b75bb] mt-3 mb-3">Pro víc informací o společnosti klikni na logo!</h4>
                  <div className="row row-cols-1 row-cols-md-3 g-4">
                    <div className="col">
                      <div className="card shadow-sm p-0 px-0 mb-5 bg-white rounded d-flex flex-column h-100">
                        <div className="card-header d-flex justify-content-between align-items-center">
                          <span className="time d-flex align-items-center">
                            <img src="/static/icons/clock.svg" alt="Clock Icon" className="mb-0 mr-2" />
                            10:00-11:00
                          </span>
                          <span className="text-right d-flex align-items-center">
                            <img src="/static/icons/location.svg" alt="Location Icon" className="mb-0 mr-2" />
                            E339
                          </span>
                        </div>
                        <div className="logo-container d-flex justify-content-center align-items-center p-4 flex-grow-1">
                          <a href="/plan?number=38" className="transition-transform duration-300 transform hover:scale-100 scale-90 flex justify-center items-center mb-0">
                            <img src="/static/lineup/pkv.svg" alt="image1" className="max-w-full h-auto" style={{ maxWidth: '80%' }} />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card shadow-sm pt-0 px-0 mb-5 bg-white rounded d-flex flex-column h-100">
                        <div className="card-header d-flex justify-content-between align-items-center">
                          <span className="time d-flex align-items-center">
                            <img src="/static/icons/clock.svg" alt="Clock Icon" className="mr-2 mb-0" />
                            13:00-14:00
                          </span>
                          <span className="text-right d-flex align-items-center">
                            <img src="/static/icons/location.svg" alt="Location Icon" className="mb-0 mr-2" />
                            E339
                          </span>
                        </div>
                        <div className="logo-container d-flex justify-content-center align-items-center p-4 flex-grow-1">
                          <a href="/plan?number=32" className="transition-transform duration-300 transform hover:scale-100 scale-90 flex justify-center items-center mb-0">
                            <img src="/static/lineup/ey.jpg" alt="image2" className="max-w-full h-auto" style={{ maxWidth: '80%' }} />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card shadow-sm pt-0 px-0 mb-5 bg-white rounded d-flex flex-column h-100">
                        <div className="card-header d-flex justify-content-between align-items-center">
                          <span className="time d-flex align-items-center">
                            <img src="/static/icons/clock.svg" alt="Clock Icon" className="mr-2 mb-0" />
                            14:00-15:00
                          </span>
                          <span className="text-right d-flex align-items-center">
                            <img src="/static/icons/location.svg" alt="Location Icon" className="mr-2 mb-0" />
                            E339
                          </span>
                        </div>
                        <div className="logo-container d-flex justify-content-center align-items-center pt-4 flex-grow-1">
                          <a href="/plan?number=37" className="transition-transform duration-300 transform hover:scale-100 scale-90 flex justify-center items-center mb-0">
                            <img src="/static/lineup/mann.jpg" alt="image3" className="max-w-full h-auto" style={{ maxWidth: '80%' }} />
                          </a>
                        </div>
                        <div className="card-body pt-0 pb-0">
                          <h5 className="card-title">Process Mining v MANN+HUMMELu</h5>
                          <p className="card-text text-muted">Co kdybych vám řekl, že byznys MANN+HUMMEL zanechává digitální stopu – a my ji těžíme jako zlato?</p>&
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default Timeline;